import {
  IconBlur,
  IconColorSwatch,
  IconCropPortrait,
  IconMaximize,
  IconPhoto,
  IconPhotoAi,
  IconPhotoScan,
  IconBackground,
  IconAbc,
  IconRubberStamp,
  IconUserScan,
  IconWand,
} from "@tabler/icons-react";

export const menu = [
  {
    icon: IconPhotoAi,
    title: "AI Image Generator",
    description: "Generate stunning images for you within seconds with AI.",
    link: "/",
  },
  {
    icon: IconMaximize,
    title: "Upscale Image",
    description: "Upscale your image without losing quality.",
    link: "/tools/upscale-image",
  },
  {
    icon: IconBackground,
    title: "Background Remover",
    description: "Remove background from images.",
    link: "/tools/background-remover",
  },

  {
    icon: IconAbc,
    title: "Image Text Remover",
    description: "Remove text from images.",
    link: "/tools/text-remover",
  },
  {
    icon: IconRubberStamp,
    title: "Watermark Remover",
    description: "Remove watermark from images.",
    link: "/tools/watermark-remover",
  },
  {
    icon: IconWand,
    title: "Retouch Photo",
    description: "Retouch your images with AI.",
    link: "/tools/retouch-photo",
  },
  {
    icon: IconUserScan,
    title: "AI Headshot Generator",
    description: "Generate headshot images with AI.",
    link: "/tools/ai-headshot-generator",
  },
  {
    icon: IconPhotoScan,
    title: "Personalized Image Generator",
    description: "Generate personalized images.",
    link: "/tools/personalized-image-generator",
  },
  {
    icon: IconPhoto,
    title: "Image Restoration",
    description: "Restore your old images.",
    link: "/tools/image-restoration",
  },
  {
    icon: IconColorSwatch,
    title: "Color Correction",
    description: "Correct color of your images.",
    link: "/tools/color-correction",
  },
  {
    icon: IconCropPortrait,
    title: "Sharpen Portraits",
    description: "Sharpen your portraits.",
    link: "/tools/sharpen-portraits",
  },
  {
    icon: IconBlur,
    title: "Unblur Image",
    description: "Unblur your images.",
    link: "/tools/unblur-image",
  },
];
