import React, { useEffect } from "react";
import {
  ActionIcon,
  Anchor,
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Drawer,
  Flex,
  Group,
  HoverCard,
  Image,
  Menu,
  Modal,
  NavLink,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  UnstyledButton,
  rem,
} from "@mantine/core";
import { navigate } from "vike/client/router";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Link } from "../components/Link";
import { Logo } from "../components/Logo";

import "@mantine/core/styles/Flex.css";
import "@mantine/core/styles/NavLink.css";
import "@mantine/core/styles/Group.css";
import "@mantine/core/styles/Burger.css";
import "@mantine/core/styles/Avatar.css";

// Drawer Style
import "@mantine/core/styles/Drawer.css";
import "@mantine/core/styles/Overlay.css";
import "@mantine/core/styles/ModalBase.css";
import "@mantine/core/styles/Modal.css";
import "@mantine/core/styles/CloseButton.css";
import "@mantine/core/styles/Popover.css";

import "@mantine/core/styles/Menu.css";

import "@mantine/core/styles/SimpleGrid.css";
import "@mantine/core/styles/Card.css";
import "@mantine/core/styles/Stack.css";
import "@mantine/core/styles/Container.css";

import "@mantine/core/styles/Center.css";
import "@mantine/core/styles/Divider.css";
import "@mantine/core/styles/ThemeIcon.css";

import AnimatedGroup from "../components/AnimatedGroup";
import { SIGNIN, SIGNUP } from "../constants/Routes";
import { ModalsProvider } from "@mantine/modals";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandX,
  IconChevronDown,
  IconLogout,
  IconUserCircle,
  IconX,
} from "@tabler/icons-react";

import classes from "./styles/MegaMenu.module.css";
import { usePageContext } from "../renderer/usePageContext";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { authenticatedUserAtom } from "../hooks/authenticatedUserAtom";
import { isAuthenticatedAtom } from "../hooks/isAuthenticatedAtom";
import { useAtom } from "jotai";
import { notifications } from "@mantine/notifications";
import useSignout from "../api/authentication/useSignout";
import useSignInWithGoogle from "../api/authentication/useSignInWithGoogle";
import { menu } from "../constants/Menu";

export default function GuestLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pageContext = usePageContext();
  const { urlPathname } = pageContext;
  const isSM = useMediaQuery("(min-width: 860px)");
  const [user, setUser] = useAtom(authenticatedUserAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
  const [opened, { toggle, close }] = useDisclosure();
  const { mutateAsync: signout } = useSignout();
  const { mutateAsync: signinWithGoogle } = useSignInWithGoogle();
  // const [
  //   isDownloadAppModalOpen,
  //   { open: openDownloadAppModal, close: closeDownloadAppModal },
  // ] = useDisclosure();

  const handleSignout = async () => {
    try {
      const res = await signout();
      // navigate("/");

      notifications.show({
        title: (
          <Title component="div" size="small" fw={500} c="#000">
            {res.data.message}
          </Title>
        ),
        message: "",
        color: "green",
        bg: "var(--mantine-color-green-1)",
      });
    } catch (error) {
      notifications.show({
        title: (
          <Title component="div" size="small" fw={500} c="#000">
            Something went wrong
          </Title>
        ),
        message: "",
        color: "red",
        bg: "var(--mantine-color-red-1)",
      });
    }
  };

  const handleSigninWithGoogle = async (credentialResponse: any) => {
    try {
      const res = await signinWithGoogle(credentialResponse);
      setIsAuthenticated(true);

      setUser(res.data);

      notifications.show({
        title: (
          <Title component="div" size="small" fw={500} c="#000">
            Signed in successfully
          </Title>
        ),
        message: "",
        color: "green",
        bg: "var(--mantine-color-green-1)",
      });

      // navigate("/");
    } catch (error: any) {
      error?.response?.data?.errors?.map((err: any) => {
        notifications.show({
          title: (
            <Title component="div" size="small" fw={500} c="#000">
              {err.message}
            </Title>
          ),
          message: "",
          color: "red",
          bg: "var(--mantine-color-red-1)",
        });
      });
    }
  };

  useGoogleOneTapLogin({
    disabled: isAuthenticated,
    onSuccess: handleSigninWithGoogle,
    onError: () => {
      notifications.show({
        title: "Unable to sign in with Google! Please try again later.",
        message: "",
        color: "red",
        bg: "var(--mantine-color-red-1)",
      });
    },
  });

  // useEffect(() => {
  //   const hasSeenModal = sessionStorage.getItem("hasSeenModal");
  //   if (!hasSeenModal) {
  //     openDownloadAppModal();
  //     sessionStorage.setItem("hasSeenModal", "true");
  //   }
  // }, []);

  return (
    <ModalsProvider>
      <AppShell id="page-container" header={{ height: 90 }} style={{ top: 50 }}>
        {/* height: 90, top: 50  */}

        <Flex
          top={0}
          w="100%"
          // bg="red"
          pos="fixed"
          justify="center"
          style={{
            zIndex: 10,
            backgroundColor: "#8BE300",
            // backgroundImage:
            //   "linear-gradient(to right, #43e97b 0%, #38f9d7 100%)",
          }}
          py={12}
          px={{
            base: 10,
            xs: 10,
            sm: 20,
            md: 30,
            lg: 40,
            xl: 50,
          }}
        >
          <Text c="#000">
            🚀 <b>ImgGen AI API is now live!</b> Integrate powerful image
            processing tools into your apps.{" "}
            <a
              style={{ color: "#000" }}
              href="https://developer.imggen.ai/docs/getting-started"
              target="_blank"
              rel="noopener noreferrer"
            >
              Check out the docs
            </a>
          </Text>
        </Flex>
        <AppShell.Header
          top={!isSM ? 73.59 : 48.8} //Desktop: 48.8, Mobile: 73.59
          className="landing-page-header"
          style={{
            borderColor: "#424242",
          }}
        >
          <Container size="xl" h="100%">
            <Flex
              style={{ height: "100%" }}
              justify="space-between"
              align="center"
            >
              <Link
                to="/"
                style={{
                  padding: "0px",
                }}
                aria-label="ImgGen Logo"
              >
                <Logo />
              </Link>

              <Group visibleFrom="md">
                <Link to="/" aria-label="AI Image Generator">
                  AI Image Generator
                </Link>

                <Link to="/tools/upscale-image" aria-label="Upscale Image">
                  Upscale Image
                </Link>

                <HoverCard
                  width={600}
                  position="bottom"
                  radius="md"
                  shadow="md"
                  withinPortal
                >
                  <HoverCard.Target>
                    <a href="#" className={classes.link}>
                      <Center inline>
                        <Box component="span" c="white" mr={5}>
                          More Tools
                        </Box>
                        <IconChevronDown
                          style={{ width: rem(16), height: rem(16) }}
                        />
                      </Center>
                    </a>
                  </HoverCard.Target>

                  <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                    <Group justify="space-between" px="md">
                      <Text fw={500}>Free Tools</Text>

                      <Button
                        size="compact-xs"
                        variant="transparent"
                        component="a"
                        href="/tools"
                      >
                        View All
                      </Button>
                    </Group>

                    <Divider my="sm" />

                    <SimpleGrid cols={2} spacing={0}>
                      {menu.slice(2).map((item, index) => (
                        <UnstyledButton
                          component="a"
                          className={classes.subLink}
                          href={item.link}
                          key={index}
                          style={{
                            color:
                              urlPathname === item.link
                                ? "var(--mantine-color-primary-filled)"
                                : "",
                            backgroundColor:
                              urlPathname === item.link
                                ? "var(--mantine-color-dark-8)"
                                : "",
                          }}
                        >
                          <Group wrap="nowrap" align="flex-start">
                            <ThemeIcon size={34} variant="default" radius="md">
                              <item.icon
                                style={{ width: rem(24), height: rem(24) }}
                              />
                            </ThemeIcon>
                            <div>
                              <Text size="sm" fw={500}>
                                {item.title}
                              </Text>
                              <Text size="xs" c="dimmed">
                                {item.description}
                              </Text>
                            </div>
                          </Group>
                        </UnstyledButton>
                      ))}
                    </SimpleGrid>
                  </HoverCard.Dropdown>
                </HoverCard>

                {/* <Link to="/app/pricing" aria-label="Contact">
                  Pricing
                </Link> */}

                <Link to="/api" aria-label="API">
                  API
                </Link>
              </Group>

              {!isAuthenticated ? (
                <>
                  <Group gap="xs" visibleFrom="md">
                    <Button
                      size="md"
                      variant="subtle"
                      onClick={() => navigate("/app/signin")}
                    >
                      Sign in
                    </Button>
                    <Button size="md" onClick={() => navigate("/app/signup")}>
                      Sign up
                    </Button>
                  </Group>
                  <Burger
                    opened={opened}
                    onClick={toggle}
                    hiddenFrom="md"
                    size="sm"
                    aria-label="Toggle navigation"
                  />
                </>
              ) : (
                <Flex align="center" gap={16}>
                  <Menu
                    withArrow
                    offset={16}
                    position="bottom-end"
                    shadow="md"
                    width={200}
                  >
                    <Menu.Target>
                      <Flex justify="center" align="center" gap={12}>
                        <Avatar
                          color="primary"
                          size="md"
                          variant="filled"
                          radius="xl"
                        >
                          {(user as any)?.first_name?.[0]}
                          {(user as any)?.last_name?.[0]}
                        </Avatar>
                        <Text
                          visibleFrom="md"
                          style={{ cursor: "pointer", userSelect: "none" }}
                        >
                          {(user as any)?.first_name}
                        </Text>
                      </Flex>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        component="a"
                        leftSection={
                          <IconUserCircle width={20} height={20} stroke={1.5} />
                        }
                        href="/app/profile"
                      >
                        Profile
                      </Menu.Item>
                      <Menu.Item
                        color="red"
                        leftSection={
                          <IconLogout width={20} height={20} stroke={1.5} />
                        }
                        onClick={handleSignout}
                      >
                        Logout
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                  <Burger
                    opened={opened}
                    onClick={toggle}
                    hiddenFrom="md"
                    size="sm"
                    aria-label="Toggle navigation"
                  />
                </Flex>
              )}
            </Flex>
          </Container>
        </AppShell.Header>

        <AppShell.Main>
          {/* <pre>
            {JSON.stringify(
              {
                user,
                isAuthenticated,
              },
              null,
              2
            )}
          </pre> */}
          <div id="page-content">{children}</div>
        </AppShell.Main>

        <AppShell.Footer
          style={{
            display: "block",
            position: "relative",
            backgroundColor: "#000",
          }}
        >
          <AnimatedGroup>
            <Group justify="center" align="center" py={60}>
              <Container w="100%" className="footer-container" size="xl">
                <Stack justify="center" align="center">
                  <SimpleGrid
                    w="100%"
                    cols={{ base: 1, sm: 2, md: 3, lg: 5 }}
                    spacing={{ base: 10, sm: 20 }}
                  >
                    <Card bg="transparent">
                      <Logo />

                      <Text fw={500} mt={0}>
                        Your simple solution for generating high resolution
                        images for you within seconds with AI.
                      </Text>

                      <Group mt={16}>
                        <ActionIcon
                          color="inherit"
                          component="a"
                          target="_blank"
                          variant="transparent"
                          href="https://www.facebook.com/imggenai"
                        >
                          <IconBrandFacebook />
                        </ActionIcon>
                        <ActionIcon
                          color="inherit"
                          component="a"
                          target="_blank"
                          variant="transparent"
                          href="https://www.instagram.com/imggen.ai/"
                        >
                          <IconBrandInstagram />
                        </ActionIcon>
                        <ActionIcon
                          color="inherit"
                          component="a"
                          target="_blank"
                          variant="transparent"
                          href="https://www.linkedin.com/company/imggen-ai"
                        >
                          <IconBrandLinkedin />
                        </ActionIcon>
                        <ActionIcon
                          color="inherit"
                          component="a"
                          target="_blank"
                          variant="transparent"
                          href="https://x.com/imggenai"
                        >
                          <IconBrandX />
                        </ActionIcon>
                      </Group>
                    </Card>

                    <Card bg="transparent" mt={6}>
                      <Title c="#FFF" size={18} order={3} fw={600}>
                        Tools
                      </Title>

                      <Stack mt={22} gap="lg">
                        {menu.map((item, index) => (
                          <Link
                            to={item.link}
                            style={{ padding: "0px" }}
                            aria-label={item.title}
                            key={index}
                          >
                            {item.title}
                          </Link>
                        ))}
                      </Stack>
                    </Card>

                    <Card bg="transparent" mt={6}>
                      <Title c="#FFF" size={18} order={3} fw={600}>
                        Our Products
                      </Title>

                      <Stack mt={22} gap="lg">
                        <Anchor
                          target="_blank"
                          href="https://listenrobo.com/?ref=imggenai"
                          className="link"
                          style={{
                            display: "block",
                            lineHeight: 1,
                            borderRadius: "var(--mantine-radius-sm)",
                            textDecoration: "none",
                            padding: "0px",
                            color: `var(--mantine-color-gray-1)`,
                            fontSize: `var(--mantine-font-size-sm)`,
                            fontWeight: 500,
                          }}
                          aria-label="ListenRobo"
                        >
                          <Stack gap={6}>
                            <span>ListenRobo</span>
                            <Text c="var(--mantine-color-gray-5)" size="sm">
                              Speech-to-text tool
                            </Text>
                          </Stack>
                        </Anchor>
                      </Stack>
                    </Card>

                    <Card bg="transparent" mt={6}>
                      <Title c="#FFF" size={18} order={3} fw={600}>
                        Integrations
                      </Title>

                      <Stack mt={22} gap="lg">
                        <Anchor
                          target="_blank"
                          href="https://www.figma.com/community/plugin/1351491937974180304/imggen-ai-background-remover"
                          className="link"
                          style={{
                            display: "block",
                            lineHeight: 1,
                            borderRadius: "var(--mantine-radius-sm)",
                            textDecoration: "none",
                            padding: "0px",
                            color: `var(--mantine-color-gray-1)`,
                            fontSize: `var(--mantine-font-size-sm)`,
                            fontWeight: 500,
                          }}
                          aria-label="ListenRobo"
                        >
                          <Stack gap={6}>
                            <span>Figma</span>
                            <Text c="var(--mantine-color-gray-5)" size="sm">
                              ImgGen AI Background Remover Figma Plugin
                            </Text>
                          </Stack>
                        </Anchor>
                      </Stack>
                    </Card>

                    <Card bg="transparent" mt={6}>
                      <Title c="#FFF" size={18} order={3} fw={600}>
                        Company
                      </Title>

                      <Stack mt={22} gap="lg">
                        <Link
                          to="/about"
                          style={{ padding: "0px" }}
                          aria-label="About"
                        >
                          About
                        </Link>
                        <Link
                          to="/blog"
                          style={{ padding: "0px" }}
                          aria-label="Blog"
                        >
                          Blog
                        </Link>
                        <Link
                          to="/contact"
                          style={{ padding: "0px" }}
                          aria-label="Contact Us"
                        >
                          Contact Us
                        </Link>
                        <Link
                          to="/legal/privacy-policy"
                          style={{ padding: "0px" }}
                          aria-label="Privacy Policy"
                        >
                          Privacy Policy
                        </Link>
                        <Link
                          to="/legal/terms-of-use"
                          style={{ padding: "0px" }}
                          aria-label="Terms of Use"
                        >
                          Terms of Use
                        </Link>
                        <Link
                          to="/legal/refund-policy"
                          style={{ padding: "0px" }}
                          aria-label="Terms of Use"
                        >
                          Refund Policy
                        </Link>
                      </Stack>
                    </Card>
                  </SimpleGrid>
                </Stack>
              </Container>
            </Group>
          </AnimatedGroup>

          <Group bg="#8BE300" justify="center" align="center" py={15}>
            <Text c="black" fw={600} lh="20px" size="xs">
              © {new Date().getFullYear()} CerebroX Technologies. All Rights
              Reserved.
            </Text>
          </Group>
        </AppShell.Footer>
      </AppShell>

      <Drawer
        size="xs"
        opened={opened}
        onClose={close}
        hiddenFrom="md"
        position="right"
      >
        {menu.map((item, index) => (
          <NavLink
            active={urlPathname === item.link}
            leftSection={<item.icon width={22} height={22} stroke={1.5} />}
            className="link"
            href={item.link}
            label={item.title}
            key={index}
            fw={500}
          />
        ))}

        <Button
          mt={24}
          fullWidth
          variant="light"
          onClick={() => navigate(SIGNIN)}
        >
          Signin
        </Button>
        <Button onClick={() => navigate(SIGNUP)} fullWidth mt={8}>
          Signup
        </Button>
      </Drawer>

      {/* <Modal
        centered
        size="xl"
        opened={isDownloadAppModalOpen}
        onClose={() => {
          closeDownloadAppModal();
        }}
        withCloseButton={false}
        styles={{
          content: {
            backgroundColor: "#8be300",
            padding: 0,
          },
          body: {
            padding: 0,
          },
        }}
      >
        <Anchor
          href="https://play.google.com/store/apps/details?id=com.cerebrox.imggenai"
          target="_blank"
        >
          <Image src="/banner.png" />
        </Anchor>

        <ActionIcon
          top={10}
          right={10}
          pos="absolute"
          onClick={() => closeDownloadAppModal()}
        >
          <IconX />
        </ActionIcon>
      </Modal> */}
    </ModalsProvider>
  );
}
